/**
 * Functionality for the Used Truck Locator feature.
 *
 * @module feature/UsedTruckLocator
 */

/**
 * Loads external Used Truck Locator.
 *
 * @requires module:project/Common.AsyncLoader
 * @memberof module:feature/UsedTruckLocator
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class UsedTruckLocator {

    /**
     * Upgrades DOM element.
     * @param {jQueryElement} $element DOM element to upgrade.
     */
    constructor($element) {
        this.$element = $element;
        this.canvasCategory = this.$element.attr('data-category');
        this.canvasUrl = this.$element.attr('data-url');
        this.canvasSelLang = this.$element.attr('data-sellang');
        this.canvasCountry = this.$element.attr('data-country');
        this.canvasOffers = this.$element.attr('data-offers');
        this.canvasNewArrivals = this.$element.attr('data-newarrived');
        this.canvasCode = this.$element.attr('data-code');
        this.customDomain = this.$element.attr('data-customdomain');
        this.firstChoice = this.$element.attr('data-firstchoice');
        this.specialTruck = this.$element.attr('data-specialtruck');

        if (this.customDomain === 'undefined') {
            this.customDomain = false;
        }
    }

    /**
     * Configures after load.
     */
    init() {
        this.createCanvas();
    }

    /* eslint-disable */
    createCanvas() {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.canvasUrl + '/js/svm.js?t='+Date.now();
        script.onload = () => {
            if (this.canvasSelLang !== 'undefined' && this.canvasSelLang.length > 0) {
                svm.saveUrlGetData({ key: 'svm_sellang', value: this.canvasSelLang });
            }
            if (this.canvasCountry !== 'undefined' && this.canvasCountry.length > 0) {
                svm.saveUrlGetData({ key: 'land', value: this.canvasCountry });
            }
            if (this.canvasCategory !== 'undefined' && this.canvasCategory.length > 0) {
                svm.saveUrlGetData({ key: 'categorie', value: this.canvasCategory });
            }
            if (this.canvasOffers !== 'undefined' && this.canvasOffers.length > 0) {
                svm.saveUrlGetData({ key: 'has_discount_price', value: '1' });
            }
            if (this.canvasNewArrivals !== 'undefined' && this.canvasNewArrivals.length > 0) {
                svm.saveUrlGetData({ key: 'entry_days', value: '7' });
            }
            if (this.firstChoice !== 'undefined' && this.firstChoice.length > 0) {
                svm.saveUrlGetData({key: 'first-choice', value: '1'});
            }
            if (this.specialTruck !== 'undefined' && this.specialTruck.length > 0) {
                svm.saveUrlGetData({ key: 'special_truck', value: '1'});
            }
            let vm = svm.create(this.canvasCode, this.canvasUrl, this.customDomain, { 'carousel': false, 'quick_search': false }, 'default');
            vm.init();
        };
        head.appendChild(script);
    }
    /* eslint-enable */
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: UsedTruckLocator,
    classAsString: 'UsedTruckLocator',
    cssClass: 'js-used-truck-locator'
});

export default UsedTruckLocator;